<script>
import AppFooter from '@/components/AppFooter'

export default {
  name: 'home',
  components: {
    AppFooter
  },
  methods: {
    scrollTo(ref) {
      const element = this.$refs[ref].$el
      window.scrollTo(0, element.offsetTop - 120);
    }
  }
};
</script>

<template>
  <div class="deti-view">
    <article>  
      <div class="full-box full-box-kurzy" style="background-image: url(images/homepage/kurzy-back.jpg;background-color: #dbf4f8">
        <div class="full-box-content">
          <h3 style="color: #f71735">Lyžiarske kurzy</h3>
          <p>Požičiame Vám výstroj na lyžiarsky kurz - za 6 dní ponúkame výstroj v akciových cenách:</p>
          <p><img alt="Akciová cena" src="@/assets/icons/price-tag.svg"> <b>25 €</b> pre žiakov MŠ a ZŠ</p>
          <p><img alt="Akciová cena" src="@/assets/icons/price-tag.svg"> <b>30 €</b> pre žiakov stredných škôl a gymnázií</p>
        </div>
      </div>

      <div class="full-box content-right full-box-mobil-rent" style="background-image: url(images/homepage/mobil-rent-back.jpg;background-color: #fff">
        <div class="full-box-content">
          <Imgix alt="Mobil rent" src="homepage/mobil-rent.png" class="mobil-rent" />
          <h3 style="color: #a6ea00">Dovoz lyžiarskej výstroje priamo k Vám!</h3>
          <p>Pre väčší komfort Vám výbavu radi dovezieme.</p>
          <p><img alt="Akciová cena" src="@/assets/icons/phone-smile.svg"> Kontaktujte MOBIL RENT - pojazdnú požičovňu - na čísle <a href="callto:+4210905357133" title="Zavolaje">0905 357 133</a>.</p>
        </div>
      </div>
    </article>

    <app-footer>
      <b>Naša ponuka:</b>
        <ul>
          <li><router-link :to="{ name: 'pozicovna' }">Požičovňa lyžiarskej, snowboardovej, skialpovej výstroje</router-link></li>
          <li><router-link :to="{ name: 'skiservis' }">Servis lyží a snowboardov</router-link></li>
          <li><router-link :to="{ name: 'predaj' }">Predaj lyží, snowboardov, lyžiarok a doplnkov</router-link></li>
          <li><router-link :to="{ name: 'deti' }">Detské výstroje</router-link></li>
          <li><a href="https://www.kolobezkyliptov.sk/" target="_blank">Bicykle</a></li>
          <li><a href="https://www.kolobezkyliptov.sk/" target="_blank">Kolobežky</a></li>
        </ul>
    </app-footer>
  </div>
</template>

<style lang="scss" scoped>
  .boxes {
    justify-content: center;

    .box {
      max-width: 400px;
      padding: .5rem 1rem;
      margin: 4% 2%;
      overflow: hidden;
      border: 3px solid #000;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      img {
        width: 65px;
        vertical-align: middle;
        margin: 1rem;
      }

      h3 {
        font-size: 21px;
        padding: 0;
        margin: 0;
        font-weight: 600;
      }

      .button-action {
        font-weight: normal;
        font-size: 1.1rem;
        border-radius: 2rem;
        padding: 0.5rem 1.5rem;
      }

      p {
        padding: .5rem 1rem;
        margin: 0;
      }

      .price {
        font-size: 28px;
        font-weight: 600;
      }
    }
  }

  .full-box {
    background-position: center right;
    background-repeat: no-repeat;
    min-height: 23rem;
    padding: 0 3%;
    font-family: 'Montserrat';
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    box-sizing: border-box;

    &.content-right {
      background-position: center left;
      align-items: flex-end;
    }

    .full-box-content {
      .mobil-rent {
        position: absolute;
        left: 95px;
        top: -30px;
        z-index: 1;
      }
    }

    p {
      line-height: 2;
    }

    h3 {
      font-weight: 200;
      font-size: 30px;
      margin: 1rem 0;
    }

    a {
      color: #01a0e2;
    }

    &.full-box-kurzy {
      p > img {
        vertical-align: sub;
        margin-right: 10px;
      }
    }

    &.full-box-mobil-rent {
      p > img {
        vertical-align: top;
        margin-right: 2px;
      }
    }

    &.full-box-night-service {
      p {
        color: #fff;
        width: 50%;
      }
    }
  }
</style>
